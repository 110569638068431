<template>
    <b-modal id="onboarding-modal" hide-footer hide-header centered>
        <OnboardingComponent></OnboardingComponent>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import OnboardingComponent from './OnboardingComponent.vue'

export default defineComponent({
    name: 'OnboardingModal',
    components: {
        OnboardingComponent,
    },
})
</script>

<style scoped>
.close-icon {
    top: 5px;
    right: 5px;
    height: 24px;
    width: 24px;
    z-index: 2;
}
</style>
